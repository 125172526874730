import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'ptone-default-artwork',
  templateUrl: './default-artwork.component.html',
  styleUrls: ['./default-artwork.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
})
export class DefaultArtworkComponent implements OnInit {
  ngOnInit(): void {}
}
