import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
import { NowPlayingBarComponent } from './now-playing-bar/now-playing-bar.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { FooterComponent } from './core-ui/footer/footer.component';

const internalModules = [
  CoreModule,
  ToolbarComponent,
  NowPlayingBarComponent,
  FooterComponent,
  AppRoutingModule,
];

const externalModules = [
  BrowserModule,
  BrowserAnimationsModule,
];

@NgModule({
  declarations: [AppComponent],
  imports: [...externalModules, ...internalModules],
  // providers: [{provide: AnimationDriver, useFactory: animationFactory}],
  bootstrap: [AppComponent],
})
export class AppModule {}
