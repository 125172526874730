import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** Cursor for paging through collections */
  ConnectionCursor: { input: any; output: any; }
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: any; output: any; }
};

export type AudioChannel = {
  __typename?: 'AudioChannel';
  aacKey?: Maybe<Scalars['String']['output']>;
  aacUrl?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  highQualityKey?: Maybe<Scalars['String']['output']>;
  highQualityUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  order?: Maybe<Scalars['Int']['output']>;
  originalFilename?: Maybe<Scalars['String']['output']>;
  originalKey?: Maybe<Scalars['String']['output']>;
  originalUrl?: Maybe<Scalars['String']['output']>;
  standardQualityKey?: Maybe<Scalars['String']['output']>;
  standardQualityUrl?: Maybe<Scalars['String']['output']>;
  transcodeStatus?: Maybe<TranscodeStatus>;
  transcoderJobId?: Maybe<Scalars['String']['output']>;
  waveformUrl?: Maybe<Scalars['String']['output']>;
};

export type AuthLoginDto = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type AuthRegisterDto = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type AuthResultDto = {
  __typename?: 'AuthResultDto';
  accessToken: Scalars['String']['output'];
  avatarUrl?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  refreshToken: Scalars['String']['output'];
  roles: Array<UserRole>;
  stripeAccountId?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type BooleanFieldComparison = {
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateAudioChannelInput = {
  /** Label of the audio channel */
  label: Scalars['String']['input'];
  /** Order of the audio channel in the track */
  order: Scalars['Int']['input'];
  /** Original filename */
  originalFilename: Scalars['String']['input'];
  /** ID of the track to which this audio channel belongs */
  trackId: Scalars['String']['input'];
};

export type CreateAudioChannelResponseDto = {
  __typename?: 'CreateAudioChannelResponseDto';
  audioChannel: AudioChannel;
  uploadUrl: Scalars['String']['output'];
};

export type CreateFavorite = {
  id: Scalars['ID']['input'];
  trackId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type CreateLicensePurchase = {
  licenseType?: InputMaybe<LicenseType>;
  paymentMethodId?: InputMaybe<Scalars['String']['input']>;
  trackId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateManyFavoritesInput = {
  /** Array of records to create */
  favorites: Array<CreateFavorite>;
};

export type CreateManyTracksInput = {
  /** Array of records to create */
  tracks: Array<CreateTrack>;
};

export type CreateOneFavoriteInput = {
  /** The record to create */
  favorite: CreateFavorite;
};

export type CreateOneTrackInput = {
  /** The record to create */
  track: CreateTrack;
};

export type CreateTrack = {
  artworkUrl?: InputMaybe<Scalars['String']['input']>;
  audioChannels?: InputMaybe<Array<AudioChannelInput>>;
  commercialLicensePrice?: InputMaybe<Scalars['Float']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  exclusiveLicensePrice?: InputMaybe<Scalars['Float']['input']>;
  genres?: InputMaybe<Array<Scalars['String']['input']>>;
  instruments?: InputMaybe<Array<Scalars['String']['input']>>;
  isExclusiveOnly?: InputMaybe<Scalars['Boolean']['input']>;
  isFree?: InputMaybe<Scalars['Boolean']['input']>;
  moods?: InputMaybe<Array<Scalars['String']['input']>>;
  playCount?: InputMaybe<Scalars['Int']['input']>;
  purchasedLicenses?: InputMaybe<Array<LicenseType>>;
  slug?: InputMaybe<Scalars['String']['input']>;
  standardLicensePrice?: InputMaybe<Scalars['Float']['input']>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type CursorPaging = {
  /** Paginate after opaque cursor */
  after?: InputMaybe<Scalars['ConnectionCursor']['input']>;
  /** Paginate before opaque cursor */
  before?: InputMaybe<Scalars['ConnectionCursor']['input']>;
  /** Paginate first */
  first?: InputMaybe<Scalars['Int']['input']>;
  /** Paginate last */
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type DateFieldComparison = {
  between?: InputMaybe<DateFieldComparisonBetween>;
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  neq?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<DateFieldComparisonBetween>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type DateFieldComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type DeleteManyFavoritesInput = {
  /** Filter to find records to delete */
  filter: FavoriteDeleteFilter;
};

export type DeleteManyResponse = {
  __typename?: 'DeleteManyResponse';
  /** The number of records deleted. */
  deletedCount: Scalars['Int']['output'];
};

export type DeleteManyTracksInput = {
  /** Filter to find records to delete */
  filter: TrackDeleteFilter;
};

export type DeleteManyUsersInput = {
  /** Filter to find records to delete */
  filter: UserDeleteFilter;
};

export type DeleteOneFavoriteInput = {
  /** The id of the record to delete. */
  id: Scalars['ID']['input'];
};

export type DeleteOneTrackInput = {
  /** The id of the record to delete. */
  id: Scalars['ID']['input'];
};

export type DeleteOneUserInput = {
  /** The id of the record to delete. */
  id: Scalars['ID']['input'];
};

export type Favorite = {
  __typename?: 'Favorite';
  id: Scalars['ID']['output'];
  trackId: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type FavoriteConnection = {
  __typename?: 'FavoriteConnection';
  /** Array of edges. */
  edges: Array<FavoriteEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type FavoriteDeleteFilter = {
  and?: InputMaybe<Array<FavoriteDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<FavoriteDeleteFilter>>;
};

export type FavoriteDeleteResponse = {
  __typename?: 'FavoriteDeleteResponse';
  id?: Maybe<Scalars['ID']['output']>;
  trackId?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type FavoriteEdge = {
  __typename?: 'FavoriteEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the Favorite */
  node: Favorite;
};

export type FavoriteFilter = {
  and?: InputMaybe<Array<FavoriteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<FavoriteFilter>>;
};

export type FavoriteSort = {
  direction: SortDirection;
  field: FavoriteSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum FavoriteSortFields {
  Id = 'id'
}

export type FavoriteUpdateFilter = {
  and?: InputMaybe<Array<FavoriteUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<FavoriteUpdateFilter>>;
};

export type GetPresignedUrlInput = {
  fileType: Scalars['String']['input'];
  uploadType: UploadType;
};

export type IdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']['input']>;
  gt?: InputMaybe<Scalars['ID']['input']>;
  gte?: InputMaybe<Scalars['ID']['input']>;
  iLike?: InputMaybe<Scalars['ID']['input']>;
  in?: InputMaybe<Array<Scalars['ID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<Scalars['ID']['input']>;
  lt?: InputMaybe<Scalars['ID']['input']>;
  lte?: InputMaybe<Scalars['ID']['input']>;
  neq?: InputMaybe<Scalars['ID']['input']>;
  notILike?: InputMaybe<Scalars['ID']['input']>;
  notIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  notLike?: InputMaybe<Scalars['ID']['input']>;
};

export type IntFieldComparison = {
  between?: InputMaybe<IntFieldComparisonBetween>;
  eq?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  neq?: InputMaybe<Scalars['Int']['input']>;
  notBetween?: InputMaybe<IntFieldComparisonBetween>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IntFieldComparisonBetween = {
  lower: Scalars['Int']['input'];
  upper: Scalars['Int']['input'];
};

export type LicensePurchase = {
  __typename?: 'LicensePurchase';
  id: Scalars['ID']['output'];
  licenseType: LicenseType;
  paymentIntentId: Scalars['String']['output'];
  paymentMethodId?: Maybe<Scalars['String']['output']>;
  purchaseAmount: Scalars['Int']['output'];
  purchasedAt: Scalars['DateTime']['output'];
  track: Track;
  trackId: Scalars['String']['output'];
  user: User;
  userId: Scalars['String']['output'];
};

export type LicensePurchaseConnection = {
  __typename?: 'LicensePurchaseConnection';
  /** Array of edges. */
  edges: Array<LicensePurchaseEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type LicensePurchaseEdge = {
  __typename?: 'LicensePurchaseEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the LicensePurchase */
  node: LicensePurchase;
};

export type LicensePurchaseFilter = {
  and?: InputMaybe<Array<LicensePurchaseFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<LicensePurchaseFilter>>;
  trackId?: InputMaybe<StringFieldComparison>;
  userId?: InputMaybe<StringFieldComparison>;
};

export type LicensePurchaseInput = {
  id: Scalars['ID']['input'];
  licenseType: LicenseType;
  paymentIntentId: Scalars['String']['input'];
  paymentMethodId?: InputMaybe<Scalars['String']['input']>;
  purchaseAmount: Scalars['Int']['input'];
  purchasedAt: Scalars['DateTime']['input'];
  trackId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type LicensePurchaseSort = {
  direction: SortDirection;
  field: LicensePurchaseSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum LicensePurchaseSortFields {
  Id = 'id',
  TrackId = 'trackId',
  UserId = 'userId'
}

export type LicenseSales = {
  __typename?: 'LicenseSales';
  count: Scalars['Int']['output'];
  licenseType: LicenseType;
  revenue: Scalars['Int']['output'];
};

export enum LicenseType {
  Commercial = 'COMMERCIAL',
  Exclusive = 'EXCLUSIVE',
  Standard = 'STANDARD'
}

export type Mutation = {
  __typename?: 'Mutation';
  addOrRemoveFavorite?: Maybe<Favorite>;
  addRole: User;
  changePassword: Scalars['Boolean']['output'];
  createAudioChannel: CreateAudioChannelResponseDto;
  createManyFavorites: Array<Favorite>;
  createManyTracks: Array<Track>;
  createOneFavorite: Favorite;
  createOneTrack: Track;
  createStripeLoginLink: Scalars['String']['output'];
  createTrack: Track;
  deleteManyFavorites: DeleteManyResponse;
  deleteManyTracks: DeleteManyResponse;
  deleteManyUsers: DeleteManyResponse;
  deleteOneFavorite: FavoriteDeleteResponse;
  deleteOneTrack: TrackDeleteResponse;
  deleteOneUser: UserDeleteResponse;
  deleteTrack: Scalars['Boolean']['output'];
  getPresignedUrl: PresignedUrlResponse;
  incrementPlayCount: Scalars['Int']['output'];
  onboardArtist: Scalars['String']['output'];
  publishTrack: Track;
  purchaseLicense: PurchaseResult;
  refreshTokens: AuthResultDto;
  removeRole: User;
  requestSignup: Scalars['Boolean']['output'];
  resetPassword: Scalars['Boolean']['output'];
  sendPasswordResetEmail: Scalars['Boolean']['output'];
  signin: AuthResultDto;
  signup: AuthResultDto;
  signupAdmin: AuthResultDto;
  signupWithToken: AuthResultDto;
  startTranscoding: AudioChannel;
  updateAudioChannel: AudioChannel;
  updateManyFavorites: UpdateManyResponse;
  updateManyTracks: UpdateManyResponse;
  updateManyUsers: UpdateManyResponse;
  updateOneFavorite: Favorite;
  updateOneTrack: Track;
  updateOneUser: User;
  updateStripeAccount: Scalars['String']['output'];
  updateTrack: Track;
  updateTrackStatus: Track;
};


export type MutationAddOrRemoveFavoriteArgs = {
  trackId: Scalars['String']['input'];
};


export type MutationAddRoleArgs = {
  role: UserRole;
  userId: Scalars['String']['input'];
};


export type MutationChangePasswordArgs = {
  currentPassword?: InputMaybe<Scalars['String']['input']>;
  newPassword: Scalars['String']['input'];
};


export type MutationCreateAudioChannelArgs = {
  createAudioChannelInput: CreateAudioChannelInput;
};


export type MutationCreateManyFavoritesArgs = {
  input: CreateManyFavoritesInput;
};


export type MutationCreateManyTracksArgs = {
  input: CreateManyTracksInput;
};


export type MutationCreateOneFavoriteArgs = {
  input: CreateOneFavoriteInput;
};


export type MutationCreateOneTrackArgs = {
  input: CreateOneTrackInput;
};


export type MutationCreateTrackArgs = {
  input: CreateTrack;
};


export type MutationDeleteManyFavoritesArgs = {
  input: DeleteManyFavoritesInput;
};


export type MutationDeleteManyTracksArgs = {
  input: DeleteManyTracksInput;
};


export type MutationDeleteManyUsersArgs = {
  input: DeleteManyUsersInput;
};


export type MutationDeleteOneFavoriteArgs = {
  input: DeleteOneFavoriteInput;
};


export type MutationDeleteOneTrackArgs = {
  input: DeleteOneTrackInput;
};


export type MutationDeleteOneUserArgs = {
  input: DeleteOneUserInput;
};


export type MutationDeleteTrackArgs = {
  trackId: Scalars['String']['input'];
};


export type MutationGetPresignedUrlArgs = {
  input: GetPresignedUrlInput;
};


export type MutationIncrementPlayCountArgs = {
  trackId: Scalars['String']['input'];
};


export type MutationPublishTrackArgs = {
  trackId: Scalars['String']['input'];
};


export type MutationPurchaseLicenseArgs = {
  input: CreateLicensePurchase;
};


export type MutationRefreshTokensArgs = {
  refreshToken: Scalars['String']['input'];
};


export type MutationRemoveRoleArgs = {
  role: UserRole;
  userId: Scalars['String']['input'];
};


export type MutationRequestSignupArgs = {
  input: AuthRegisterDto;
};


export type MutationResetPasswordArgs = {
  newPassword: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationSendPasswordResetEmailArgs = {
  email: Scalars['String']['input'];
};


export type MutationSigninArgs = {
  input: AuthLoginDto;
};


export type MutationSignupArgs = {
  input: AuthRegisterDto;
};


export type MutationSignupAdminArgs = {
  input: AuthRegisterDto;
};


export type MutationSignupWithTokenArgs = {
  token: Scalars['String']['input'];
};


export type MutationStartTranscodingArgs = {
  audioChannelId: Scalars['String']['input'];
};


export type MutationUpdateAudioChannelArgs = {
  id: Scalars['String']['input'];
  updateAudioChannelInput: UpdateAudioChannelInput;
};


export type MutationUpdateManyFavoritesArgs = {
  input: UpdateManyFavoritesInput;
};


export type MutationUpdateManyTracksArgs = {
  input: UpdateManyTracksInput;
};


export type MutationUpdateManyUsersArgs = {
  input: UpdateManyUsersInput;
};


export type MutationUpdateOneFavoriteArgs = {
  input: UpdateOneFavoriteInput;
};


export type MutationUpdateOneTrackArgs = {
  input: UpdateOneTrackInput;
};


export type MutationUpdateOneUserArgs = {
  input: UpdateOneUserInput;
};


export type MutationUpdateTrackArgs = {
  trackId: Scalars['String']['input'];
  updateInput: UpdateTrack;
};


export type MutationUpdateTrackStatusArgs = {
  status: TrackStatus;
  trackId: Scalars['String']['input'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  /** The cursor of the last returned record. */
  endCursor?: Maybe<Scalars['ConnectionCursor']['output']>;
  /** true if paging forward and there are more records. */
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  /** true if paging backwards and there are more records. */
  hasPreviousPage?: Maybe<Scalars['Boolean']['output']>;
  /** The cursor of the first returned record. */
  startCursor?: Maybe<Scalars['ConnectionCursor']['output']>;
};

export type PaginatedTracksResponse = {
  __typename?: 'PaginatedTracksResponse';
  hasNextPage: Scalars['Boolean']['output'];
  totalCount: Scalars['Int']['output'];
  tracks: Array<Track>;
};

export type PresignedUrlResponse = {
  __typename?: 'PresignedUrlResponse';
  key: Scalars['String']['output'];
  saveUrl: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type PurchaseResult = {
  __typename?: 'PurchaseResult';
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type Query = {
  __typename?: 'Query';
  favorite: Favorite;
  favorites: FavoriteConnection;
  findPublishedTracksByUser: Array<Track>;
  findTrackByIdentifier: Track;
  getDownloadUrl: Scalars['String']['output'];
  getListenUrl: Scalars['String']['output'];
  getMyFavoriteTracks: Array<Track>;
  getMyPurchasedTracks: Array<Track>;
  getMyUploadedTracks: Array<Track>;
  getPublishedTracks: PaginatedTracksResponse;
  getTags: Array<TagsResponse>;
  getUsersByRoles: Array<User>;
  isStripeAccountVerified: Scalars['Boolean']['output'];
  licensePurchase: LicensePurchase;
  licensePurchases: LicensePurchaseConnection;
  refreshAuthData: AuthResultDto;
  searchArtists: Array<User>;
  track: Track;
  tracks: TrackConnection;
  tracksByTags: Array<Track>;
  user: User;
  userByEmail: User;
  userByIdentifier: User;
  users: UserConnection;
};


export type QueryFavoriteArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFavoritesArgs = {
  filter?: FavoriteFilter;
  paging?: CursorPaging;
  sorting?: Array<FavoriteSort>;
};


export type QueryFindPublishedTracksByUserArgs = {
  username: Scalars['String']['input'];
};


export type QueryFindTrackByIdentifierArgs = {
  identifier: Scalars['String']['input'];
};


export type QueryGetDownloadUrlArgs = {
  audioChannelId: Scalars['String']['input'];
};


export type QueryGetListenUrlArgs = {
  audioChannelId: Scalars['String']['input'];
};


export type QueryGetPublishedTracksArgs = {
  genres?: InputMaybe<Array<Scalars['String']['input']>>;
  instruments?: InputMaybe<Array<Scalars['String']['input']>>;
  isExclusiveOnly?: InputMaybe<Scalars['Boolean']['input']>;
  isFree?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: Scalars['Int']['input'];
  moods?: InputMaybe<Array<Scalars['String']['input']>>;
  page?: Scalars['Int']['input'];
};


export type QueryGetUsersByRolesArgs = {
  limit: Scalars['Int']['input'];
  roles: Array<UserRole>;
};


export type QueryIsStripeAccountVerifiedArgs = {
  userId: Scalars['String']['input'];
};


export type QueryLicensePurchaseArgs = {
  id: Scalars['ID']['input'];
};


export type QueryLicensePurchasesArgs = {
  filter?: LicensePurchaseFilter;
  paging?: CursorPaging;
  sorting?: Array<LicensePurchaseSort>;
};


export type QuerySearchArtistsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query: Scalars['String']['input'];
};


export type QueryTrackArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTracksArgs = {
  filter?: TrackFilter;
  paging?: CursorPaging;
  sorting?: Array<TrackSort>;
};


export type QueryTracksByTagsArgs = {
  tags: Array<Scalars['String']['input']>;
};


export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUserByEmailArgs = {
  email: Scalars['String']['input'];
};


export type QueryUserByIdentifierArgs = {
  identifier: Scalars['String']['input'];
};


export type QueryUsersArgs = {
  filter?: UserFilter;
  paging?: CursorPaging;
  sorting?: Array<UserSort>;
};

/** Sort Directions */
export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** Sort Nulls Options */
export enum SortNulls {
  NullsFirst = 'NULLS_FIRST',
  NullsLast = 'NULLS_LAST'
}

export type StringFieldComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  iLike?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notILike?: InputMaybe<Scalars['String']['input']>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type TagsResponse = {
  __typename?: 'TagsResponse';
  count: Scalars['Float']['output'];
  tag: Scalars['String']['output'];
};

export type Track = {
  __typename?: 'Track';
  artist: User;
  artworkUrl?: Maybe<Scalars['String']['output']>;
  audioChannels?: Maybe<Array<Maybe<AudioChannel>>>;
  commercialLicensePrice?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  exclusiveLicensePrice?: Maybe<Scalars['Float']['output']>;
  favorites?: Maybe<Favorite>;
  genres: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  instruments: Array<Scalars['String']['output']>;
  isExclusiveOnly: Scalars['Boolean']['output'];
  isFree: Scalars['Boolean']['output'];
  licensePurchases?: Maybe<LicensePurchase>;
  likedByUser?: Maybe<Scalars['Boolean']['output']>;
  moods: Array<Scalars['String']['output']>;
  playCount: Scalars['Int']['output'];
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  purchasedLicenses?: Maybe<Array<Maybe<LicenseType>>>;
  salesSummary: Array<LicenseSales>;
  slug?: Maybe<Scalars['String']['output']>;
  standardLicensePrice?: Maybe<Scalars['Float']['output']>;
  status: TrackStatus;
  tags: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  totalRevenue: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userLicensePurchases: Array<UserLicensePurchase>;
};

export type TrackConnection = {
  __typename?: 'TrackConnection';
  /** Array of edges. */
  edges: Array<TrackEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type TrackDeleteFilter = {
  and?: InputMaybe<Array<TrackDeleteFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isExclusiveOnly?: InputMaybe<BooleanFieldComparison>;
  isFree?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<TrackDeleteFilter>>;
  playCount?: InputMaybe<IntFieldComparison>;
  publishedAt?: InputMaybe<DateFieldComparison>;
  slug?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<TrackStatusFilterComparison>;
  title?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type TrackDeleteResponse = {
  __typename?: 'TrackDeleteResponse';
  artworkUrl?: Maybe<Scalars['String']['output']>;
  audioChannels?: Maybe<Array<AudioChannel>>;
  commercialLicensePrice?: Maybe<Scalars['Float']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  exclusiveLicensePrice?: Maybe<Scalars['Float']['output']>;
  genres?: Maybe<Array<Scalars['String']['output']>>;
  id?: Maybe<Scalars['ID']['output']>;
  instruments?: Maybe<Array<Scalars['String']['output']>>;
  isExclusiveOnly?: Maybe<Scalars['Boolean']['output']>;
  isFree?: Maybe<Scalars['Boolean']['output']>;
  likedByUser?: Maybe<Scalars['Boolean']['output']>;
  moods?: Maybe<Array<Scalars['String']['output']>>;
  playCount?: Maybe<Scalars['Int']['output']>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  purchasedLicenses?: Maybe<Array<LicenseType>>;
  slug?: Maybe<Scalars['String']['output']>;
  standardLicensePrice?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<TrackStatus>;
  tags?: Maybe<Array<Scalars['String']['output']>>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type TrackEdge = {
  __typename?: 'TrackEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the Track */
  node: Track;
};

export type TrackFilter = {
  and?: InputMaybe<Array<TrackFilter>>;
  artist?: InputMaybe<TrackFilterUserFilter>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isExclusiveOnly?: InputMaybe<BooleanFieldComparison>;
  isFree?: InputMaybe<BooleanFieldComparison>;
  licensePurchases?: InputMaybe<TrackFilterLicensePurchaseFilter>;
  or?: InputMaybe<Array<TrackFilter>>;
  playCount?: InputMaybe<IntFieldComparison>;
  publishedAt?: InputMaybe<DateFieldComparison>;
  slug?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<TrackStatusFilterComparison>;
  title?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type TrackFilterLicensePurchaseFilter = {
  and?: InputMaybe<Array<TrackFilterLicensePurchaseFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<TrackFilterLicensePurchaseFilter>>;
  trackId?: InputMaybe<StringFieldComparison>;
  userId?: InputMaybe<StringFieldComparison>;
};

export type TrackFilterUserFilter = {
  and?: InputMaybe<Array<TrackFilterUserFilter>>;
  biography?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<TrackFilterUserFilter>>;
  stripeAccountId?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  username?: InputMaybe<StringFieldComparison>;
};

export type TrackInput = {
  artworkUrl?: InputMaybe<Scalars['String']['input']>;
  audioChannels?: InputMaybe<Array<InputMaybe<AudioChannelInput>>>;
  commercialLicensePrice?: InputMaybe<Scalars['Float']['input']>;
  createdAt: Scalars['DateTime']['input'];
  deletedAt: Scalars['DateTime']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  exclusiveLicensePrice?: InputMaybe<Scalars['Float']['input']>;
  genres: Array<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  instruments: Array<Scalars['String']['input']>;
  isExclusiveOnly: Scalars['Boolean']['input'];
  isFree: Scalars['Boolean']['input'];
  likedByUser?: InputMaybe<Scalars['Boolean']['input']>;
  moods: Array<Scalars['String']['input']>;
  playCount?: Scalars['Int']['input'];
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  purchasedLicenses?: InputMaybe<Array<InputMaybe<LicenseType>>>;
  slug?: InputMaybe<Scalars['String']['input']>;
  standardLicensePrice?: InputMaybe<Scalars['Float']['input']>;
  status?: TrackStatus;
  tags: Array<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  updatedAt: Scalars['DateTime']['input'];
};

export type TrackSort = {
  direction: SortDirection;
  field: TrackSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum TrackSortFields {
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Id = 'id',
  IsExclusiveOnly = 'isExclusiveOnly',
  IsFree = 'isFree',
  PlayCount = 'playCount',
  PublishedAt = 'publishedAt',
  Slug = 'slug',
  Status = 'status',
  Title = 'title',
  UpdatedAt = 'updatedAt'
}

export enum TrackStatus {
  Archived = 'ARCHIVED',
  ExclusiveLicenseSold = 'EXCLUSIVE_LICENSE_SOLD',
  Private = 'PRIVATE',
  Published = 'PUBLISHED',
  Rejected = 'REJECTED',
  Unpublished = 'UNPUBLISHED'
}

export type TrackStatusFilterComparison = {
  eq?: InputMaybe<TrackStatus>;
  gt?: InputMaybe<TrackStatus>;
  gte?: InputMaybe<TrackStatus>;
  iLike?: InputMaybe<TrackStatus>;
  in?: InputMaybe<Array<TrackStatus>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<TrackStatus>;
  lt?: InputMaybe<TrackStatus>;
  lte?: InputMaybe<TrackStatus>;
  neq?: InputMaybe<TrackStatus>;
  notILike?: InputMaybe<TrackStatus>;
  notIn?: InputMaybe<Array<TrackStatus>>;
  notLike?: InputMaybe<TrackStatus>;
};

export type TrackUpdateFilter = {
  and?: InputMaybe<Array<TrackUpdateFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isExclusiveOnly?: InputMaybe<BooleanFieldComparison>;
  isFree?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<TrackUpdateFilter>>;
  playCount?: InputMaybe<IntFieldComparison>;
  publishedAt?: InputMaybe<DateFieldComparison>;
  slug?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<TrackStatusFilterComparison>;
  title?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export enum TranscodeStatus {
  Complete = 'COMPLETE',
  Error = 'ERROR',
  InProgress = 'IN_PROGRESS',
  Never = 'NEVER'
}

export type UpdateAudioChannelInput = {
  label?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  originalBucket?: InputMaybe<Scalars['String']['input']>;
  originalFilename?: InputMaybe<Scalars['String']['input']>;
  originalFormat?: InputMaybe<Scalars['String']['input']>;
  originalKey?: InputMaybe<Scalars['String']['input']>;
  originalUrl?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFavorite = {
  id?: InputMaybe<Scalars['ID']['input']>;
  trackId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateManyFavoritesInput = {
  /** Filter used to find fields to update */
  filter: FavoriteUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateFavorite;
};

export type UpdateManyResponse = {
  __typename?: 'UpdateManyResponse';
  /** The number of records updated. */
  updatedCount: Scalars['Int']['output'];
};

export type UpdateManyTracksInput = {
  /** Filter used to find fields to update */
  filter: TrackUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateTrack;
};

export type UpdateManyUsersInput = {
  /** Filter used to find fields to update */
  filter: UserUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateUser;
};

export type UpdateOneFavoriteInput = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: UpdateFavorite;
};

export type UpdateOneTrackInput = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: UpdateTrack;
};

export type UpdateOneUserInput = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: UpdateUser;
};

export type UpdateTrack = {
  artworkUrl?: InputMaybe<Scalars['String']['input']>;
  audioChannels?: InputMaybe<Array<AudioChannelInput>>;
  commercialLicensePrice?: InputMaybe<Scalars['Float']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  exclusiveLicensePrice?: InputMaybe<Scalars['Float']['input']>;
  genres?: InputMaybe<Array<Scalars['String']['input']>>;
  instruments?: InputMaybe<Array<Scalars['String']['input']>>;
  isExclusiveOnly?: InputMaybe<Scalars['Boolean']['input']>;
  isFree?: InputMaybe<Scalars['Boolean']['input']>;
  moods?: InputMaybe<Array<Scalars['String']['input']>>;
  playCount?: InputMaybe<Scalars['Int']['input']>;
  purchasedLicenses?: InputMaybe<Array<LicenseType>>;
  slug?: InputMaybe<Scalars['String']['input']>;
  standardLicensePrice?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<TrackStatus>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUser = {
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  biography?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
  websiteUrl?: InputMaybe<Scalars['String']['input']>;
};

export enum UploadType {
  Artwork = 'ARTWORK',
  Audio = 'AUDIO',
  Avatar = 'AVATAR'
}

export type User = {
  __typename?: 'User';
  avatarUrl?: Maybe<Scalars['String']['output']>;
  biography?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  favorites?: Maybe<UserFavoritesConnection>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  roles?: Maybe<Array<UserRole>>;
  stripeAccountId?: Maybe<Scalars['String']['output']>;
  tracks?: Maybe<UserTracksConnection>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  username?: Maybe<Scalars['String']['output']>;
  websiteUrl?: Maybe<Scalars['String']['output']>;
};


export type UserFavoritesArgs = {
  filter?: FavoriteFilter;
  paging?: CursorPaging;
  sorting?: Array<FavoriteSort>;
};


export type UserTracksArgs = {
  filter?: TrackFilter;
  paging?: CursorPaging;
  sorting?: Array<TrackSort>;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  /** Array of edges. */
  edges: Array<UserEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type UserDeleteFilter = {
  and?: InputMaybe<Array<UserDeleteFilter>>;
  biography?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<UserDeleteFilter>>;
  stripeAccountId?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  username?: InputMaybe<StringFieldComparison>;
};

export type UserDeleteResponse = {
  __typename?: 'UserDeleteResponse';
  avatarUrl?: Maybe<Scalars['String']['output']>;
  biography?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  roles?: Maybe<Array<UserRole>>;
  stripeAccountId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  username?: Maybe<Scalars['String']['output']>;
  websiteUrl?: Maybe<Scalars['String']['output']>;
};

export type UserEdge = {
  __typename?: 'UserEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the User */
  node: User;
};

export type UserFavoritesConnection = {
  __typename?: 'UserFavoritesConnection';
  /** Array of edges. */
  edges: Array<FavoriteEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type UserFilter = {
  and?: InputMaybe<Array<UserFilter>>;
  biography?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  favorites?: InputMaybe<UserFilterFavoriteFilter>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<UserFilter>>;
  stripeAccountId?: InputMaybe<StringFieldComparison>;
  tracks?: InputMaybe<UserFilterTrackFilter>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  username?: InputMaybe<StringFieldComparison>;
};

export type UserFilterFavoriteFilter = {
  and?: InputMaybe<Array<UserFilterFavoriteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<UserFilterFavoriteFilter>>;
};

export type UserFilterTrackFilter = {
  and?: InputMaybe<Array<UserFilterTrackFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isExclusiveOnly?: InputMaybe<BooleanFieldComparison>;
  isFree?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<UserFilterTrackFilter>>;
  playCount?: InputMaybe<IntFieldComparison>;
  publishedAt?: InputMaybe<DateFieldComparison>;
  slug?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<TrackStatusFilterComparison>;
  title?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type UserLicensePurchase = {
  __typename?: 'UserLicensePurchase';
  licenseType: LicenseType;
  purchaseAmount: Scalars['Int']['output'];
  purchasedAt: Scalars['DateTime']['output'];
};

export enum UserRole {
  Admin = 'ADMIN',
  Artist = 'ARTIST',
  Customer = 'CUSTOMER',
  FeaturedArtist = 'FEATURED_ARTIST',
  Staff = 'STAFF'
}

export type UserSort = {
  direction: SortDirection;
  field: UserSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum UserSortFields {
  Biography = 'biography',
  CreatedAt = 'createdAt',
  Email = 'email',
  Id = 'id',
  Name = 'name',
  StripeAccountId = 'stripeAccountId',
  UpdatedAt = 'updatedAt',
  Username = 'username'
}

export type UserTracksConnection = {
  __typename?: 'UserTracksConnection';
  /** Array of edges. */
  edges: Array<TrackEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type UserUpdateFilter = {
  and?: InputMaybe<Array<UserUpdateFilter>>;
  biography?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<UserUpdateFilter>>;
  stripeAccountId?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  username?: InputMaybe<StringFieldComparison>;
};

export type AudioChannelInput = {
  aacKey?: InputMaybe<Scalars['String']['input']>;
  aacUrl?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  highQualityKey?: InputMaybe<Scalars['String']['input']>;
  highQualityUrl?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  label: Scalars['String']['input'];
  order?: InputMaybe<Scalars['Int']['input']>;
  originalFilename?: InputMaybe<Scalars['String']['input']>;
  originalKey?: InputMaybe<Scalars['String']['input']>;
  originalUrl?: InputMaybe<Scalars['String']['input']>;
  standardQualityKey?: InputMaybe<Scalars['String']['input']>;
  standardQualityUrl?: InputMaybe<Scalars['String']['input']>;
  transcodeStatus?: InputMaybe<TranscodeStatus>;
  transcoderJobId?: InputMaybe<Scalars['String']['input']>;
  waveformUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CreateAudioChannelMutationVariables = Exact<{
  trackId: Scalars['String']['input'];
  label: Scalars['String']['input'];
  order: Scalars['Int']['input'];
  originalFilename: Scalars['String']['input'];
}>;


export type CreateAudioChannelMutation = { __typename?: 'Mutation', createAudioChannel: { __typename?: 'CreateAudioChannelResponseDto', uploadUrl: string, audioChannel: { __typename: 'AudioChannel', id: string, label: string, order?: number | null, originalFilename?: string | null } } };

export type StartTranscodingMutationVariables = Exact<{
  audioChannelId: Scalars['String']['input'];
}>;


export type StartTranscodingMutation = { __typename?: 'Mutation', startTranscoding: { __typename?: 'AudioChannel', id: string, label: string, originalFilename?: string | null, transcoderJobId?: string | null, transcodeStatus?: TranscodeStatus | null } };

export type GetListenUrlQueryVariables = Exact<{
  audioChannelId: Scalars['String']['input'];
}>;


export type GetListenUrlQuery = { __typename?: 'Query', getListenUrl: string };

export type GetDownloadUrlQueryVariables = Exact<{
  audioChannelId: Scalars['String']['input'];
}>;


export type GetDownloadUrlQuery = { __typename?: 'Query', getDownloadUrl: string };

export type UpdateAudioChannelMutationVariables = Exact<{
  audioChannelId: Scalars['String']['input'];
  label: Scalars['String']['input'];
  order: Scalars['Int']['input'];
}>;


export type UpdateAudioChannelMutation = { __typename?: 'Mutation', updateAudioChannel: { __typename: 'AudioChannel', id: string, label: string, order?: number | null, originalFilename?: string | null } };

export type RequestSignupMutationVariables = Exact<{
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type RequestSignupMutation = { __typename?: 'Mutation', requestSignup: boolean };

export type SignupWithTokenMutationVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type SignupWithTokenMutation = { __typename?: 'Mutation', signupWithToken: { __typename?: 'AuthResultDto', accessToken: string, refreshToken: string, id: string, email: string, name: string, username?: string | null, avatarUrl?: string | null, stripeAccountId?: string | null, roles: Array<UserRole> } };

export type SignupMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  name: Scalars['String']['input'];
}>;


export type SignupMutation = { __typename?: 'Mutation', signup: { __typename?: 'AuthResultDto', accessToken: string, refreshToken: string, id: string, email: string, name: string, username?: string | null, avatarUrl?: string | null, stripeAccountId?: string | null, roles: Array<UserRole> } };

export type SigninMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type SigninMutation = { __typename?: 'Mutation', signin: { __typename?: 'AuthResultDto', accessToken: string, refreshToken: string, id: string, email: string, name: string, username?: string | null, avatarUrl?: string | null, stripeAccountId?: string | null, roles: Array<UserRole> } };

export type SendPasswordResetEmailMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type SendPasswordResetEmailMutation = { __typename?: 'Mutation', sendPasswordResetEmail: boolean };

export type ResetPasswordMutationVariables = Exact<{
  token: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword: boolean };

export type ChangePasswordMutationVariables = Exact<{
  currentPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
}>;


export type ChangePasswordMutation = { __typename?: 'Mutation', changePassword: boolean };

export type RefreshAuthDataQueryVariables = Exact<{ [key: string]: never; }>;


export type RefreshAuthDataQuery = { __typename?: 'Query', refreshAuthData: { __typename?: 'AuthResultDto', accessToken: string, refreshToken: string, id: string, email: string, name: string, username?: string | null, avatarUrl?: string | null, stripeAccountId?: string | null, roles: Array<UserRole> } };

export type RefreshTokensMutationVariables = Exact<{
  refreshToken: Scalars['String']['input'];
}>;


export type RefreshTokensMutation = { __typename?: 'Mutation', refreshTokens: { __typename?: 'AuthResultDto', accessToken: string, refreshToken: string, id: string, email: string, name: string, username?: string | null, avatarUrl?: string | null, stripeAccountId?: string | null, roles: Array<UserRole> } };

export type OnboardArtistMutationVariables = Exact<{ [key: string]: never; }>;


export type OnboardArtistMutation = { __typename?: 'Mutation', onboardArtist: string };

export type CreateStripeLoginLinkMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateStripeLoginLinkMutation = { __typename?: 'Mutation', createStripeLoginLink: string };

export type IsStripeAccountVerifiedQueryVariables = Exact<{
  userId: Scalars['String']['input'];
}>;


export type IsStripeAccountVerifiedQuery = { __typename?: 'Query', isStripeAccountVerified: boolean };

export type GetTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTagsQuery = { __typename?: 'Query', getTags: Array<{ __typename?: 'TagsResponse', tag: string, count: number }> };

export type CreateTrackMutationVariables = Exact<{
  title: Scalars['String']['input'];
}>;


export type CreateTrackMutation = { __typename?: 'Mutation', createTrack: { __typename: 'Track', id: string, title: string, createdAt: any, artist: { __typename: 'User', id: string, email?: string | null, username?: string | null, name: string } } };

export type FindTrackByIdentifierQueryVariables = Exact<{
  identifier: Scalars['String']['input'];
}>;


export type FindTrackByIdentifierQuery = { __typename?: 'Query', findTrackByIdentifier: { __typename: 'Track', id: string, title: string, description?: string | null, artworkUrl?: string | null, totalRevenue: number, status: TrackStatus, createdAt: any, publishedAt?: any | null, instruments: Array<string>, genres: Array<string>, moods: Array<string>, tags: Array<string>, likedByUser?: boolean | null, standardLicensePrice?: number | null, commercialLicensePrice?: number | null, exclusiveLicensePrice?: number | null, purchasedLicenses?: Array<LicenseType | null> | null, playCount: number, isExclusiveOnly: boolean, isFree: boolean, artist: { __typename: 'User', id: string, name: string, username?: string | null, email?: string | null }, audioChannels?: Array<{ __typename: 'AudioChannel', id: string, label: string, transcoderJobId?: string | null, transcodeStatus?: TranscodeStatus | null, originalFilename?: string | null, standardQualityUrl?: string | null, highQualityUrl?: string | null, order?: number | null, originalUrl?: string | null, originalKey?: string | null, aacKey?: string | null, duration?: number | null, waveformUrl?: string | null } | null> | null, salesSummary: Array<{ __typename?: 'LicenseSales', licenseType: LicenseType, count: number, revenue: number }>, userLicensePurchases: Array<{ __typename: 'UserLicensePurchase', licenseType: LicenseType, purchaseAmount: number, purchasedAt: any }> } };

export type GetMyUploadedTracksQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyUploadedTracksQuery = { __typename?: 'Query', getMyUploadedTracks: Array<{ __typename: 'Track', id: string, title: string, status: TrackStatus, artworkUrl?: string | null, createdAt: any, publishedAt?: any | null, totalRevenue: number, instruments: Array<string>, genres: Array<string>, moods: Array<string>, tags: Array<string>, likedByUser?: boolean | null, standardLicensePrice?: number | null, commercialLicensePrice?: number | null, exclusiveLicensePrice?: number | null, purchasedLicenses?: Array<LicenseType | null> | null, playCount: number, isExclusiveOnly: boolean, isFree: boolean, artist: { __typename: 'User', id: string, name: string, username?: string | null }, audioChannels?: Array<{ __typename: 'AudioChannel', id: string, order?: number | null, label: string, duration?: number | null, transcodeStatus?: TranscodeStatus | null, originalFilename?: string | null, standardQualityUrl?: string | null, waveformUrl?: string | null } | null> | null }> };

export type FindPublishedTracksByUserQueryVariables = Exact<{
  username: Scalars['String']['input'];
}>;


export type FindPublishedTracksByUserQuery = { __typename?: 'Query', findPublishedTracksByUser: Array<{ __typename: 'Track', id: string, title: string, status: TrackStatus, artworkUrl?: string | null, createdAt: any, publishedAt?: any | null, totalRevenue: number, instruments: Array<string>, genres: Array<string>, moods: Array<string>, tags: Array<string>, likedByUser?: boolean | null, standardLicensePrice?: number | null, commercialLicensePrice?: number | null, exclusiveLicensePrice?: number | null, purchasedLicenses?: Array<LicenseType | null> | null, playCount: number, isExclusiveOnly: boolean, isFree: boolean, artist: { __typename: 'User', id: string, name: string, username?: string | null }, audioChannels?: Array<{ __typename: 'AudioChannel', id: string, order?: number | null, label: string, duration?: number | null, transcodeStatus?: TranscodeStatus | null, originalFilename?: string | null, standardQualityUrl?: string | null, waveformUrl?: string | null } | null> | null, userLicensePurchases: Array<{ __typename: 'UserLicensePurchase', licenseType: LicenseType, purchaseAmount: number, purchasedAt: any }> }> };

export type GetPublishedTracksQueryVariables = Exact<{
  page: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  genres?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  instruments?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  moods?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  isFree?: InputMaybe<Scalars['Boolean']['input']>;
  isExclusiveOnly?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetPublishedTracksQuery = { __typename?: 'Query', getPublishedTracks: { __typename: 'PaginatedTracksResponse', totalCount: number, hasNextPage: boolean, tracks: Array<{ __typename: 'Track', id: string, title: string, status: TrackStatus, artworkUrl?: string | null, publishedAt?: any | null, instruments: Array<string>, genres: Array<string>, moods: Array<string>, tags: Array<string>, likedByUser?: boolean | null, standardLicensePrice?: number | null, commercialLicensePrice?: number | null, exclusiveLicensePrice?: number | null, purchasedLicenses?: Array<LicenseType | null> | null, playCount: number, isExclusiveOnly: boolean, isFree: boolean, artist: { __typename: 'User', id: string, name: string, username?: string | null }, audioChannels?: Array<{ __typename: 'AudioChannel', id: string, label: string, order?: number | null, transcodeStatus?: TranscodeStatus | null, originalFilename?: string | null, standardQualityUrl?: string | null, duration?: number | null, waveformUrl?: string | null } | null> | null }> } };

export type UpdateTrackMutationVariables = Exact<{
  trackId: Scalars['String']['input'];
  updateInput: UpdateTrack;
}>;


export type UpdateTrackMutation = { __typename?: 'Mutation', updateTrack: { __typename: 'Track', id: string, title: string, description?: string | null, status: TrackStatus, artworkUrl?: string | null, instruments: Array<string>, genres: Array<string>, moods: Array<string>, tags: Array<string>, standardLicensePrice?: number | null, commercialLicensePrice?: number | null, exclusiveLicensePrice?: number | null, purchasedLicenses?: Array<LicenseType | null> | null, isExclusiveOnly: boolean, isFree: boolean, artist: { __typename: 'User', id: string, name: string, username?: string | null }, audioChannels?: Array<{ __typename: 'AudioChannel', id: string, label: string, order?: number | null, duration?: number | null, transcodeStatus?: TranscodeStatus | null, originalFilename?: string | null, standardQualityUrl?: string | null, waveformUrl?: string | null } | null> | null } };

export type IncrementPlayCountMutationVariables = Exact<{
  trackId: Scalars['String']['input'];
}>;


export type IncrementPlayCountMutation = { __typename?: 'Mutation', incrementPlayCount: number };

export type GetMyFavoriteTracksQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyFavoriteTracksQuery = { __typename?: 'Query', getMyFavoriteTracks: Array<{ __typename: 'Track', id: string, title: string, status: TrackStatus, artworkUrl?: string | null, publishedAt?: any | null, likedByUser?: boolean | null, instruments: Array<string>, genres: Array<string>, moods: Array<string>, tags: Array<string>, standardLicensePrice?: number | null, commercialLicensePrice?: number | null, exclusiveLicensePrice?: number | null, purchasedLicenses?: Array<LicenseType | null> | null, playCount: number, isExclusiveOnly: boolean, isFree: boolean, artist: { __typename: 'User', id: string, name: string, username?: string | null }, audioChannels?: Array<{ __typename: 'AudioChannel', id: string, order?: number | null, label: string, duration?: number | null, transcodeStatus?: TranscodeStatus | null, originalFilename?: string | null, standardQualityUrl?: string | null, waveformUrl?: string | null } | null> | null, userLicensePurchases: Array<{ __typename: 'UserLicensePurchase', licenseType: LicenseType, purchaseAmount: number, purchasedAt: any }> }> };

export type PurchaseLicenseMutationVariables = Exact<{
  input: CreateLicensePurchase;
}>;


export type PurchaseLicenseMutation = { __typename?: 'Mutation', purchaseLicense: { __typename: 'PurchaseResult', success: boolean, message?: string | null } };

export type GetMyPurchasedTracksQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyPurchasedTracksQuery = { __typename?: 'Query', getMyPurchasedTracks: Array<{ __typename: 'Track', id: string, title: string, status: TrackStatus, artworkUrl?: string | null, publishedAt?: any | null, instruments: Array<string>, genres: Array<string>, moods: Array<string>, tags: Array<string>, likedByUser?: boolean | null, standardLicensePrice?: number | null, commercialLicensePrice?: number | null, exclusiveLicensePrice?: number | null, isExclusiveOnly: boolean, isFree: boolean, artist: { __typename: 'User', id: string, name: string, username?: string | null }, audioChannels?: Array<{ __typename: 'AudioChannel', id: string, order?: number | null, label: string, duration?: number | null, transcodeStatus?: TranscodeStatus | null, originalFilename?: string | null, standardQualityUrl?: string | null, waveformUrl?: string | null } | null> | null, userLicensePurchases: Array<{ __typename: 'UserLicensePurchase', licenseType: LicenseType, purchaseAmount: number, purchasedAt: any }> }> };

export type UpdateTrackStatusMutationVariables = Exact<{
  trackId: Scalars['String']['input'];
  status: TrackStatus;
}>;


export type UpdateTrackStatusMutation = { __typename?: 'Mutation', updateTrackStatus: { __typename: 'Track', id: string, title: string, status: TrackStatus } };

export type DeleteTrackMutationVariables = Exact<{
  trackId: Scalars['String']['input'];
}>;


export type DeleteTrackMutation = { __typename?: 'Mutation', deleteTrack: boolean };

export type AddOrRemoveFavoriteMutationVariables = Exact<{
  trackId: Scalars['String']['input'];
}>;


export type AddOrRemoveFavoriteMutation = { __typename?: 'Mutation', addOrRemoveFavorite?: { __typename?: 'Favorite', id: string, userId: string, trackId: string } | null };

export type GetPresignedUrlMutationVariables = Exact<{
  uploadType: UploadType;
}>;


export type GetPresignedUrlMutation = { __typename?: 'Mutation', getPresignedUrl: { __typename?: 'PresignedUrlResponse', url: string, saveUrl: string, key: string } };

export type UserByEmailQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type UserByEmailQuery = { __typename?: 'Query', userByEmail: { __typename: 'User', id: string, email?: string | null, name: string, username?: string | null, avatarUrl?: string | null, websiteUrl?: string | null, biography?: string | null, stripeAccountId?: string | null, roles?: Array<UserRole> | null } };

export type UserByIdentifierQueryVariables = Exact<{
  identifier: Scalars['String']['input'];
}>;


export type UserByIdentifierQuery = { __typename?: 'Query', userByIdentifier: { __typename: 'User', id: string, email?: string | null, name: string, username?: string | null, avatarUrl?: string | null, websiteUrl?: string | null, biography?: string | null, stripeAccountId?: string | null, roles?: Array<UserRole> | null } };

export type UpdateOneUserMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  update: UpdateUser;
}>;


export type UpdateOneUserMutation = { __typename?: 'Mutation', updateOneUser: { __typename: 'User', id: string, email?: string | null, name: string, username?: string | null, avatarUrl?: string | null, websiteUrl?: string | null, biography?: string | null, stripeAccountId?: string | null, roles?: Array<UserRole> | null } };

export type GetUsersByRolesQueryVariables = Exact<{
  roles: Array<UserRole> | UserRole;
  limit: Scalars['Int']['input'];
}>;


export type GetUsersByRolesQuery = { __typename?: 'Query', getUsersByRoles: Array<{ __typename?: 'User', id: string, name: string, username?: string | null, avatarUrl?: string | null, websiteUrl?: string | null, biography?: string | null, roles?: Array<UserRole> | null }> };

export type SearchArtistsQueryVariables = Exact<{
  query: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;


export type SearchArtistsQuery = { __typename?: 'Query', searchArtists: Array<{ __typename?: 'User', id: string, name: string, username?: string | null, avatarUrl?: string | null, roles?: Array<UserRole> | null }> };


export const CreateAudioChannelDocument = gql`
    mutation createAudioChannel($trackId: String!, $label: String!, $order: Int!, $originalFilename: String!) {
  createAudioChannel(
    createAudioChannelInput: {trackId: $trackId, label: $label, order: $order, originalFilename: $originalFilename}
  ) {
    audioChannel {
      id
      label
      order
      originalFilename
      __typename
    }
    uploadUrl
  }
}
    `;
export const StartTranscodingDocument = gql`
    mutation startTranscoding($audioChannelId: String!) {
  startTranscoding(audioChannelId: $audioChannelId) {
    id
    label
    originalFilename
    transcoderJobId
    transcodeStatus
  }
}
    `;
export const GetListenUrlDocument = gql`
    query getListenUrl($audioChannelId: String!) {
  getListenUrl(audioChannelId: $audioChannelId)
}
    `;
export const GetDownloadUrlDocument = gql`
    query getDownloadUrl($audioChannelId: String!) {
  getDownloadUrl(audioChannelId: $audioChannelId)
}
    `;
export const UpdateAudioChannelDocument = gql`
    mutation updateAudioChannel($audioChannelId: String!, $label: String!, $order: Int!) {
  updateAudioChannel(
    id: $audioChannelId
    updateAudioChannelInput: {label: $label, order: $order}
  ) {
    id
    label
    order
    originalFilename
    __typename
  }
}
    `;
export const RequestSignupDocument = gql`
    mutation RequestSignup($email: String!, $name: String!, $password: String!) {
  requestSignup(input: {email: $email, name: $name, password: $password})
}
    `;
export const SignupWithTokenDocument = gql`
    mutation SignupWithToken($token: String!) {
  signupWithToken(token: $token) {
    accessToken
    refreshToken
    id
    email
    name
    username
    avatarUrl
    stripeAccountId
    roles
  }
}
    `;
export const SignupDocument = gql`
    mutation Signup($email: String!, $password: String!, $name: String!) {
  signup(input: {email: $email, password: $password, name: $name}) {
    accessToken
    refreshToken
    id
    email
    name
    username
    avatarUrl
    stripeAccountId
    roles
  }
}
    `;
export const SigninDocument = gql`
    mutation Signin($email: String!, $password: String!) {
  signin(input: {email: $email, password: $password}) {
    accessToken
    refreshToken
    id
    email
    name
    username
    avatarUrl
    stripeAccountId
    roles
  }
}
    `;
export const SendPasswordResetEmailDocument = gql`
    mutation SendPasswordResetEmail($email: String!) {
  sendPasswordResetEmail(email: $email)
}
    `;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($token: String!, $newPassword: String!) {
  resetPassword(token: $token, newPassword: $newPassword)
}
    `;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($currentPassword: String!, $newPassword: String!) {
  changePassword(currentPassword: $currentPassword, newPassword: $newPassword)
}
    `;
export const RefreshAuthDataDocument = gql`
    query RefreshAuthData {
  refreshAuthData {
    accessToken
    refreshToken
    id
    email
    name
    username
    avatarUrl
    stripeAccountId
    roles
  }
}
    `;
export const RefreshTokensDocument = gql`
    mutation RefreshTokens($refreshToken: String!) {
  refreshTokens(refreshToken: $refreshToken) {
    accessToken
    refreshToken
    id
    email
    name
    username
    avatarUrl
    stripeAccountId
    roles
  }
}
    `;
export const OnboardArtistDocument = gql`
    mutation onboardArtist {
  onboardArtist
}
    `;
export const CreateStripeLoginLinkDocument = gql`
    mutation createStripeLoginLink {
  createStripeLoginLink
}
    `;
export const IsStripeAccountVerifiedDocument = gql`
    query IsStripeAccountVerified($userId: String!) {
  isStripeAccountVerified(userId: $userId)
}
    `;
export const GetTagsDocument = gql`
    query GetTags {
  getTags {
    tag
    count
  }
}
    `;
export const CreateTrackDocument = gql`
    mutation CreateTrack($title: String!) {
  createTrack(input: {title: $title}) {
    id
    title
    createdAt
    __typename
    artist {
      id
      email
      username
      name
      __typename
    }
  }
}
    `;
export const FindTrackByIdentifierDocument = gql`
    query FindTrackByIdentifier($identifier: String!) {
  findTrackByIdentifier(identifier: $identifier) {
    id
    title
    description
    artworkUrl
    totalRevenue
    status
    createdAt
    publishedAt
    instruments
    genres
    moods
    tags
    likedByUser
    standardLicensePrice
    commercialLicensePrice
    exclusiveLicensePrice
    purchasedLicenses
    playCount
    isExclusiveOnly
    isFree
    __typename
    artist {
      id
      name
      username
      email
      __typename
    }
    audioChannels {
      id
      label
      transcoderJobId
      transcodeStatus
      originalFilename
      standardQualityUrl
      highQualityUrl
      order
      originalUrl
      originalKey
      aacKey
      duration
      waveformUrl
      __typename
    }
    salesSummary {
      licenseType
      count
      revenue
    }
    userLicensePurchases {
      licenseType
      purchaseAmount
      purchasedAt
      __typename
    }
  }
}
    `;
export const GetMyUploadedTracksDocument = gql`
    query GetMyUploadedTracks {
  getMyUploadedTracks {
    id
    title
    status
    artworkUrl
    createdAt
    publishedAt
    totalRevenue
    instruments
    genres
    moods
    tags
    likedByUser
    standardLicensePrice
    commercialLicensePrice
    exclusiveLicensePrice
    purchasedLicenses
    playCount
    isExclusiveOnly
    isFree
    __typename
    artist {
      id
      name
      username
      __typename
    }
    audioChannels {
      id
      order
      label
      duration
      transcodeStatus
      originalFilename
      standardQualityUrl
      waveformUrl
      __typename
    }
  }
}
    `;
export const FindPublishedTracksByUserDocument = gql`
    query FindPublishedTracksByUser($username: String!) {
  findPublishedTracksByUser(username: $username) {
    id
    title
    status
    artworkUrl
    createdAt
    publishedAt
    totalRevenue
    instruments
    genres
    moods
    tags
    likedByUser
    standardLicensePrice
    commercialLicensePrice
    exclusiveLicensePrice
    purchasedLicenses
    playCount
    isExclusiveOnly
    isFree
    __typename
    artist {
      id
      name
      username
      __typename
    }
    audioChannels {
      id
      order
      label
      duration
      transcodeStatus
      originalFilename
      standardQualityUrl
      waveformUrl
      __typename
    }
    userLicensePurchases {
      licenseType
      purchaseAmount
      purchasedAt
      __typename
    }
  }
}
    `;
export const GetPublishedTracksDocument = gql`
    query GetPublishedTracks($page: Int!, $limit: Int!, $genres: [String!], $instruments: [String!], $moods: [String!], $isFree: Boolean, $isExclusiveOnly: Boolean) {
  getPublishedTracks(
    page: $page
    limit: $limit
    genres: $genres
    instruments: $instruments
    moods: $moods
    isFree: $isFree
    isExclusiveOnly: $isExclusiveOnly
  ) {
    tracks {
      id
      title
      status
      artworkUrl
      publishedAt
      instruments
      genres
      moods
      tags
      likedByUser
      standardLicensePrice
      commercialLicensePrice
      exclusiveLicensePrice
      purchasedLicenses
      playCount
      isExclusiveOnly
      isFree
      __typename
      artist {
        id
        name
        username
        __typename
      }
      audioChannels {
        id
        label
        order
        transcodeStatus
        originalFilename
        standardQualityUrl
        duration
        waveformUrl
        __typename
      }
    }
    totalCount
    hasNextPage
    __typename
  }
}
    `;
export const UpdateTrackDocument = gql`
    mutation UpdateTrack($trackId: String!, $updateInput: UpdateTrack!) {
  updateTrack(trackId: $trackId, updateInput: $updateInput) {
    id
    title
    description
    status
    artworkUrl
    instruments
    genres
    moods
    tags
    standardLicensePrice
    commercialLicensePrice
    exclusiveLicensePrice
    purchasedLicenses
    isExclusiveOnly
    isFree
    __typename
    artist {
      id
      name
      username
      __typename
    }
    audioChannels {
      id
      label
      order
      duration
      transcodeStatus
      originalFilename
      standardQualityUrl
      waveformUrl
      __typename
    }
  }
}
    `;
export const IncrementPlayCountDocument = gql`
    mutation IncrementPlayCount($trackId: String!) {
  incrementPlayCount(trackId: $trackId)
}
    `;
export const GetMyFavoriteTracksDocument = gql`
    query GetMyFavoriteTracks {
  getMyFavoriteTracks {
    id
    title
    status
    artworkUrl
    publishedAt
    likedByUser
    instruments
    genres
    moods
    tags
    standardLicensePrice
    commercialLicensePrice
    exclusiveLicensePrice
    purchasedLicenses
    playCount
    isExclusiveOnly
    isFree
    __typename
    artist {
      id
      name
      username
      __typename
    }
    audioChannels {
      id
      order
      label
      duration
      transcodeStatus
      originalFilename
      standardQualityUrl
      waveformUrl
      __typename
    }
    userLicensePurchases {
      licenseType
      purchaseAmount
      purchasedAt
      __typename
    }
  }
}
    `;
export const PurchaseLicenseDocument = gql`
    mutation PurchaseLicense($input: CreateLicensePurchase!) {
  purchaseLicense(input: $input) {
    success
    message
    __typename
  }
}
    `;
export const GetMyPurchasedTracksDocument = gql`
    query GetMyPurchasedTracks {
  getMyPurchasedTracks {
    id
    title
    status
    artworkUrl
    publishedAt
    instruments
    genres
    moods
    tags
    likedByUser
    standardLicensePrice
    commercialLicensePrice
    exclusiveLicensePrice
    isExclusiveOnly
    isFree
    __typename
    artist {
      id
      name
      username
      __typename
    }
    audioChannels {
      id
      order
      label
      duration
      transcodeStatus
      originalFilename
      standardQualityUrl
      waveformUrl
      __typename
    }
    userLicensePurchases {
      licenseType
      purchaseAmount
      purchasedAt
      __typename
    }
  }
}
    `;
export const UpdateTrackStatusDocument = gql`
    mutation UpdateTrackStatus($trackId: String!, $status: TrackStatus!) {
  updateTrackStatus(trackId: $trackId, status: $status) {
    id
    title
    status
    __typename
  }
}
    `;
export const DeleteTrackDocument = gql`
    mutation DeleteTrack($trackId: String!) {
  deleteTrack(trackId: $trackId)
}
    `;
export const AddOrRemoveFavoriteDocument = gql`
    mutation addOrRemoveFavorite($trackId: String!) {
  addOrRemoveFavorite(trackId: $trackId) {
    id
    userId
    trackId
  }
}
    `;
export const GetPresignedUrlDocument = gql`
    mutation GetPresignedUrl($uploadType: UploadType!) {
  getPresignedUrl(input: {fileType: "image/jpeg", uploadType: $uploadType}) {
    url
    saveUrl
    key
  }
}
    `;
export const UserByEmailDocument = gql`
    query userByEmail($email: String!) {
  userByEmail(email: $email) {
    id
    email
    name
    username
    avatarUrl
    websiteUrl
    biography
    stripeAccountId
    roles
    __typename
  }
}
    `;
export const UserByIdentifierDocument = gql`
    query userByIdentifier($identifier: String!) {
  userByIdentifier(identifier: $identifier) {
    id
    email
    name
    username
    avatarUrl
    websiteUrl
    biography
    stripeAccountId
    roles
    __typename
  }
}
    `;
export const UpdateOneUserDocument = gql`
    mutation UpdateOneUser($id: ID!, $update: UpdateUser!) {
  updateOneUser(input: {id: $id, update: $update}) {
    id
    email
    name
    username
    avatarUrl
    websiteUrl
    biography
    stripeAccountId
    roles
    __typename
  }
}
    `;
export const GetUsersByRolesDocument = gql`
    query getUsersByRoles($roles: [UserRole!]!, $limit: Int!) {
  getUsersByRoles(roles: $roles, limit: $limit) {
    id
    name
    username
    avatarUrl
    websiteUrl
    biography
    roles
  }
}
    `;
export const SearchArtistsDocument = gql`
    query SearchArtists($query: String!, $limit: Int, $offset: Int) {
  searchArtists(query: $query, limit: $limit, offset: $offset) {
    id
    name
    username
    avatarUrl
    roles
  }
}
    `;