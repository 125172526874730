import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  signal,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  getPtoneDialogConfig,
  getPtoneSnackBarConfig,
} from '../../../utils/ptone-material.config';
import { filter } from 'rxjs/operators';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { Subject } from 'rxjs';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ResizableImageDirective } from '../../directives/resizable-image/resizable-image.directive';
import { TrackEntity } from '../../../models/track-entity.model';
import { LicenseType } from '@pallotone/data-access';
import {
  COMMERCIAL_LICENSE_DESCRIPTION,
  COMMERCIAL_LICENSE_PRICE,
  EXCLUSIVE_LICENSE_DESCRIPTION,
  EXCLUSIVE_LICENSE_PRICE,
  STANDARD_LICENSE_DESCRIPTION,
  STANDARD_LICENSE_PRICE,
} from '../../../core/constants/license.constants';

@Component({
  selector: 'ptone-license-dialog',
  templateUrl: './license-dialog.component.html',
  styleUrls: ['./license-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    RouterModule,
    ResizableImageDirective,
  ],
})
export class LicenseDialog implements OnInit, OnDestroy {
  track: TrackEntity;
  licenseType = LicenseType;
  selectedLicense = signal<LicenseType>(null);
  snackBarConfig = getPtoneSnackBarConfig();

  licenseOptions = [
    {
      type: LicenseType.Standard,
      price: STANDARD_LICENSE_PRICE,
      shortDescription: STANDARD_LICENSE_DESCRIPTION.split('. '),
    },
    {
      type: LicenseType.Commercial,
      price: COMMERCIAL_LICENSE_PRICE,
      shortDescription: COMMERCIAL_LICENSE_DESCRIPTION.split('. '),
    },
    {
      type: LicenseType.Exclusive,
      price: EXCLUSIVE_LICENSE_PRICE,
      shortDescription: EXCLUSIVE_LICENSE_DESCRIPTION.split('. '),
    },
  ];

  private destroy$ = new Subject<void>();

  static showLicenseDialog(matDialog: MatDialog, track: TrackEntity) {
    let config: MatDialogConfig = getPtoneDialogConfig();
    config.panelClass = 'darker-bg';
    config.width = '480px';
    config.data = { track: track };
    let factDialogRef: MatDialogRef<LicenseDialog> = matDialog.open(
      LicenseDialog,
      config,
    );
    return factDialogRef;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: LicenseDialogData,
    public dialogRef: MatDialogRef<LicenseDialog>,
    private router: Router,
  ) {
    if (dialogData.track) {
      this.track = dialogData.track;
    }
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.closeDialog();
      });
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setSelectedLicense(licenseType: LicenseType) {
    if (this.selectedLicense() === licenseType) {
      this.selectedLicense.set(null);
    } else {
      this.selectedLicense.set(licenseType);
    }
  }

  navigateToCheckout() {
    if (this.selectedLicense()) {
      this.router.navigate(['/checkout', this.track.id], {
        queryParams: { license_type: this.selectedLicense() },
      });
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }
}

class LicenseDialogData {
  track: TrackEntity;
}
