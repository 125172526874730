<footer class="footer">
  <div class="container footer-flex-container">
    <div class="logo-section">
      <a class="ptone-symbol-link" routerLink="/">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66.92 56.3">
          <title>Pallotone symbol</title>
          <path
            d="M42.91 22a20.08 20.08 0 1 0 0 28.4 20.1 20.1 0 0 0 0-28.4zM32.25 34.21l-1.77-1.77a.81.81 0 0 1-.24-.6.88.88 0 0 1 .24-.6l6.08-6.08a.86.86 0 0 1 1.19 0l1.77 1.77a.85.85 0 0 1 0 1.19l-6.08 6.08a.85.85 0 0 1-.6.24.71.71 0 0 1-.59-.23z"
            fill="#ffffff"
          ></path>
          <path
            d="M11.7 55.69l.26-.26a.81.81 0 0 0 .25-.62.84.84 0 0 0-.28-.62 24.62 24.62 0 1 1 41.44-18 .87.87 0 0 0 .87.87h11.81a.87.87 0 0 0 .87-.87 36.56 36.56 0 0 0-2.68-13.75 36.9 36.9 0 0 0-7.37-11.54A34 34 0 0 0 32 0a32 32 0 0 0-21.49 55.71.85.85 0 0 0 1.19-.02z"
            fill="#ffffff"
          ></path>
        </svg>
      </a>
      <div class="logo-section-copy">
        <h3 class="montserrat">License multitrack music</h3>
        <p class="sub-header">
          License studio-quality music and create custom mixes to fit your
          project.
        </p>
        <a mat-stroked-button routerLink="/">BROWSE MUSIC</a>
      </div>
    </div>
    <div class="menu-section">
      <h3 class="menu-section-header montserrat">Browse</h3>
      <div class="menu-link-wrapper">
        <a class="menu-section-link" routerLink="/">Music</a>
      </div>
      <div class="menu-link-wrapper">
        <a class="menu-section-link" routerLink="/library">Library</a>
      </div>
      <div class="menu-link-wrapper">
        <a class="menu-section-link" routerLink="/upload">Submit music</a>
      </div>
    </div>
    <div class="menu-section">
      <h3 class="menu-section-header montserrat">About</h3>
      <div class="menu-link-wrapper">
        <a class="menu-section-link" routerLink="/about-us">About us</a>
      </div>
      <div class="menu-link-wrapper">
        <a class="menu-section-link" routerLink="/legal/terms">Terms of use</a>
      </div>
      <div class="menu-link-wrapper">
        <a class="menu-section-link" routerLink="/legal/privacy"
          >Privacy policy</a
        >
      </div>
      <div class="menu-link-wrapper">
        <a class="menu-section-link" routerLink="/legal/license">License agreement</a>
      </div>
    </div>
    <div class="menu-section">
      <h3 class="menu-section-header montserrat">Support</h3>
      <div class="menu-link-wrapper">
        <a class="menu-section-link" href="mailto:team@pallotone.com"
          >Email us</a
        >
      </div>
    </div>
  </div>
  <div class="container">
    <div class="bottom-bar">
      <div class="copyright">
        &copy; 2025 Pallotone, Inc. All rights reserved.
      </div>
      <div class="social-links">
        <a
          class="social-icon-link"
          target="_blank"
          href="http://facebook.com/pallotone"
          ><img src="assets/images/common/zuck-white.svg"
        /></a>
        <a
          class="social-icon-link"
          target="_blank"
          href="http://instagram.com/pallotone"
          ><img src="assets/images/common/systrom-white.svg"
        /></a>
        <a
          class="social-icon-link"
          target="_blank"
          href="http://twitter.com/pallotone"
          ><img src="assets/images/common/dorsey-white.svg"
        /></a>
      </div>
    </div>
  </div>
</footer>
