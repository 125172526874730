import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'ptone-audio-snackbar',
  templateUrl: './audio-snackbar.component.html',
  styleUrls: ['./audio-snackbar.component.scss'],
  imports: [CommonModule],
})
export class AudioSnackbarComponent implements OnInit {
  config: AudioSnackbarConfig;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {}

  ngOnInit(): void {
    this.config = this.data.config;
  }
}

export class AudioSnackbarConfig {
  name: string;
  artworkUrl: string;
  playlistName: string;
  playlistId: number;
  trackId: string;
  albumId: number;
  purchased: boolean;

  public constructor(init?: Partial<AudioSnackbarConfig>) {
    Object.assign(this, init);
  }
}
