import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import { RevelationService } from '../core/revelation/revelation.service';
import { TrackSettings } from '../models/track-settings.model';
import { TrackState } from '../models/track-state.enum';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { TrackEntity } from '../models/track-entity.model';

@Component({
  selector: 'ptone-stem-buttons',
  templateUrl: './stem-buttons.component.html',
  styleUrls: ['./stem-buttons.component.scss'],
  imports: [CommonModule, MatProgressSpinnerModule, MatButtonModule],
})
export class StemButtonsComponent implements OnInit, OnDestroy {
  private playerService = inject(RevelationService);
  private changeDetectorRef = inject(ChangeDetectorRef);

  state = TrackState;

  @Input() track: TrackEntity;
  @Input() trackSettings: TrackSettings;
  activeTrack: TrackEntity;

  private destroy$ = new Subject<void>();

  ngOnInit() {
    this.playerService.activeTrack$
      .pipe(takeUntil(this.destroy$))
      .subscribe((track: TrackEntity) => {
        this.activeTrack = track;
      });
    this.track.settings.change$.subscribe((trackSettings) => {
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  togglePlay(trackId: string, $event, stemIndexes?: number[]) {
    $event.stopPropagation();
    if ($event instanceof MouseEvent) {
      this.playerService.togglePlay(trackId, stemIndexes, $event);
    } else if ($event instanceof TouchEvent) {
      this.playerService.unlock($event);
    }
  }

  setTrackSolo(which: number, $event?) {
    $event?.stopPropagation();
    if (this.activeTrack && this.activeTrack.id === this.track.id) {
      this.track.settings.toggleSolo(which).save();
    } else {
      this.track.settings.setSolo(which, true).save();
      this.togglePlay(this.track.id, $event, [which]);
    }
  }

  mix(event?: UIEvent) {
    this.playerService.investigate(this.track.id, event);
  }
}
