import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  inject,
  signal,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatOptionModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { User } from '@pallotone/data-access';
import { UserService } from '../../core/user/user.service';
import { getImageResizeUrl } from '../../core/utils/image-resize-url.utils';

@Component({
  selector: 'ptone-toolbar-search',
  templateUrl: './toolbar-search.component.html',
  styleUrls: ['./toolbar-search.component.scss'],
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MatOptionModule,
    MatButtonModule,
    MatIconModule,
    ReactiveFormsModule,
  ],
})
export class ToolbarSearchComponent implements OnInit, OnDestroy {
  private userService = inject(UserService);
  public router = inject(Router);
  private changeDetectorRef = inject(ChangeDetectorRef);

  userSearchInput = new FormControl<string>('');
  userStringResults = signal<User[]>([]);
  searching = signal(false);

  private destroy$ = new Subject<void>();

  ngOnInit() {
    this.userSearchInput.valueChanges
      .pipe(takeUntil(this.destroy$), debounceTime(300))
      .subscribe((v: string) => this.userStringAutocomplete(v));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  userStringAutocomplete(inputValue: string) {
    if (this.userSearchInput.value) {
      this.searching.set(true);
    }
    if (inputValue !== '') {
      this.userService
        .searchArtists(inputValue, 10)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (users: User[]) => {
            this.userStringResults.set(users);
            this.searching.set(false);
          },
          error: (error) => {
            this.searching.set(false);
          },
        });
    } else {
      this.userStringResults.set([]);
      this.changeDetectorRef.detectChanges();
    }
  }

  getArtistName(value: User) {
    return value.name;
  }

  getImageResizeUrl(url: string, size: string): string {
    return getImageResizeUrl(url, size);
  }
}
