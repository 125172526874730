import { Component, OnInit, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../../core/auth/auth.service';
import { RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { AuthResultDto } from '@pallotone/data-access';

@Component({
  selector: 'ptone-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  imports: [CommonModule, MatButtonModule, RouterLink],
})
export class FooterComponent implements OnInit {
  private authService = inject(AuthService);

  authDto$: Observable<AuthResultDto | null>;

  constructor() {
    this.authDto$ = this.authService.authDto$;
  }

  ngOnInit(): void {}
}
