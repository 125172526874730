import { inject, Injectable, Injector } from '@angular/core';
import { ApolloClientOptions, ApolloLink } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { environment } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ApolloService {
  private httpLink = inject(HttpLink);
  private injector = inject(Injector);

  createApollo(): ApolloClientOptions<any> {
    const authLink = setContext(async (_, { headers }) => {
      const authService = this.injector.get(AuthService);
      if (authService.isLoggedIn() && authService.isTokenExpired()) {
        try {
          await lastValueFrom(authService.refreshTokens());
        } catch (error) {
          authService.signout();
        }
      }

      const token = authService.getAccessToken();
      return {
        headers: {
          ...headers,
          Authorization: token ? `Bearer ${token}` : '',
        },
      };
    });
    const http = this.httpLink.create({
      uri: environment.apiUrl,
    });
    return {
      cache: new InMemoryCache({
        typePolicies: {
          Track: { keyFields: ['id'] },
          User: { keyFields: ['id'] },
          AudioChannel: { keyFields: ['id'] },
        },
      }),
      link: ApolloLink.from([authLink, http]),
    };
  }
}
