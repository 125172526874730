import { Component, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { getPtoneDialogConfig } from '../../../utils/ptone-material.config';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'ptone-keyboard-shortcuts-dialog',
  templateUrl: './keyboard-shortcuts-dialog.component.html',
  styleUrls: ['./keyboard-shortcuts-dialog.component.scss'],
  imports: [CommonModule, MatButtonModule, MatIconModule, MatDialogModule],
})
export class KeyboardShortcutsDialog implements OnInit {
  static showKeyboardShortcutsDialog(matDialog: MatDialog) {
    let config: MatDialogConfig = getPtoneDialogConfig();
    config.width = '480px';
    matDialog.open(KeyboardShortcutsDialog, config);
  }

  constructor(public dialogRef: MatDialogRef<KeyboardShortcutsDialog>) {}

  ngOnInit(): void {}
}
